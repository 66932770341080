import FadeIn from "react-fade-in";
import React from "react";

export default function Art() {
  return (
    <>
      <h1>art <span role="img" aria-label="palette">🎨</span></h1>
      <FadeIn delay="100">
        <p>nothing here yet ♡ ´･ᴗ･ `♡</p>
      </FadeIn>
    </>
  );
}
